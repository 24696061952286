<template>
  <div class="customBack">
    <v-app-bar app color="#2E9195" elevation="0">
      <v-spacer />
      <v-toolbar-items>
        <v-menu
          v-model="userDropdown"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          ripple="false"
        >
          <template v-slot:activator="{ on }">
            <v-btn
              class="elevation-0 text-capitalize"
              color="white"
              text
              v-on="on"
            >
              <v-avatar
                color="primary lighten-1"
                size="36"
                class="mr-2"
              ></v-avatar>
              {{ user.name }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title class="primary darken-2">
              <v-list dense class="primary darken-2">
                <v-list-item>
                  <v-list-item-avatar color="primary"></v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="white--text"
                      >{{ user.name }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card-title>
            <v-list>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon>mdi-account-settings-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Perfil</v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout">
                <v-list-item-icon>
                  <v-icon dark color="red">mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Cerrar sesión</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
    </v-app-bar>
    <v-main style="height: 100%">
      <v-container fluid class="fill-height">
        <v-row class="justify-center ma-auto">
          <v-col cols="12">
            <v-img :src="mainLogo" contain height="12em"></v-img>
          </v-col>
        </v-row>
        <v-row class="justify-center ma-auto">
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              class="title text-wrap"
              color="primary"
              @click="goBDaily"
            >
              Business Daily
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              @click="goPP"
            >
              Product Performance
            </v-btn>
          </v-col>

          <!--<v-col cols="12" sm="3">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              @click="goPP2"
            >
              Product Performance 2
            </v-btn>
          </v-col>-->
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              disabled
            >
              Recursos Humanos
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              disabled
            >
              Producción
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              disabled
            >
              Calidad
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              height="125"
              block
              color="primary"
              class="title text-wrap"
              disabled
            >
              Fase 6
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import menu from "@/utils/menu";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {},
  data: () => ({
    userDropdown: false,
    drawer: null,
    menus: menu,
    menuWidth: 300, // Ancho de la barra lateral principal
    mainLogo: require("@/assets/branding/logo_letras.svg"), // Este es el logo que aparece en la barra lateral
    prominentLogo: true, // Logo pequeño o Grande
    logoBackColor: "primary", // Color de fondo para el logo de la barra lateral
    sideBackColor: "primary darken-1", // Color de fondo de la barra lateral
    darkTheme: true, // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra lateral)
    appBarColor: "", // Color de la barra principal de la app
    appBarDark: false, // Tema obscuro ( letras en blanco en caso de colores obscuros de la barra principal)
  }),
  computed: {
    ...mapGetters(["user"]),
    version() {
      return process.env.VUE_APP_VERSIONP;
    },
    currentYear() {
      return new Date().getFullYear();
    },
    crumbs: function () {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
          path: path,
          // to: breadcrumbArray[idx - 1]
          //     ? "/" + breadcrumbArray[idx - 1].path + "/" + path
          //     : "/" + path,
          text: this.$route.matched[idx].meta.breadCrumb || path,
        });
        return breadcrumbArray;
      }, []);
      return breadcrumbs;
    },
  },
  methods: {
    genChildTarget(item, subItem) {
      if (subItem.href) return;
      if (subItem.component) {
        return {
          name: subItem.component,
        };
      }
      return { name: `${item.group}/${subItem.name}` };
    },
    hasPermission(view) {
      return this.user.permissions.some((e) => e.name === view);
    },
    goBDaily() {
      //this.$router.push({ name: "files" });
      this.$router.push({ name: "business" });
    },
    goPP() {
      this.$router.push({ name: "performance" });
    },
    logout() {
      this.$http
        .post("logout")
        .then(() => {})
        .finally(() => {
          this.$store.dispatch("reset");
          this.$router.push({ name: "login" });
          this.$store.dispatch("working", false);
        });
    },
  },
};
</script>

<style scoped>
.customBack {
  height: 100%;
  background: rgb(46, 145, 149);
  background: linear-gradient(
    to bottom,
    rgba(46, 145, 149, 1) 0%,
    rgba(41, 106, 109, 1) 100%
  );
}
</style>
